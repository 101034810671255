import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import { useKeycloak } from '@react-keycloak/web';

import { CustomSvgIcon } from 'components/svg-icon/svg-icon';
import { ReactComponent as CSVIcon } from 'assets/icons/file_csv.svg';
import { FontSizes } from 'constants/font-sizes';
import { ToastMessage } from 'components/toast/toast-message';
import { Filter } from 'store/modules/filters/filters.reducer';
import { requestReportGeneration } from '../../../../utils/general/request-report-generation';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { paths } from '../../../../constants/routes';
import { Colors } from '../../../../constants/colors';
import { StyledTooltip } from 'components/tooltip/tooltip';

interface Props {
  total: number;
  activeSortedOrder?: string;
  activeSortedField?: string;
  tableFilters?: Filter;
  apiUrl: string;
  fileName: string;
  searchQuery?: string;
  startDate?: Date | null | string;
  endDate?: Date | null | string;
  dataTestId: string;
  searchBy?: any;
  source?: string;
  paramIncluded?: boolean;
}

export const CsvDownload = ({
  total,
  apiUrl,
  activeSortedOrder,
  activeSortedField,
  fileName,
  tableFilters,
  endDate,
  startDate,
  searchQuery,
  dataTestId,
  searchBy,
  source,
  paramIncluded = false,
}: Props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();

  const downloadHandler = async () => {
    const errorToastMessage = () => {
      toast(
        <ToastMessage title="Your request was unsuccessful" type="error" />,
      );
    };

    const successToastMessage = () => {
      toast(
        <ToastMessage
          title={
            <Typography>
              Request registered for the generation of this report you can check
              the status on{' '}
              <Typography
                onClick={() => navigate(paths.reporting)}
                sx={{ display: 'inline', color: Colors.blue, padding: '5px' }}
              >
                Reports
              </Typography>
            </Typography>
          }
          type={'info'}
        />,
      );
    };

    const regenerationToastMessage = () => {
      toast(
        <ToastMessage
          title={
            <Typography>
              Requested Reported already generated. Please check the
              <Typography
                onClick={() => navigate(paths.reporting)}
                sx={{ display: 'inline', color: Colors.blue, padding: '5px' }}
              >
                Reports
              </Typography>
              Section
            </Typography>
          }
          type={'info'}
        />,
      );
    };

    await requestReportGeneration({
      total,
      sortBy: activeSortedOrder
        ? `${activeSortedOrder}${activeSortedField}`
        : undefined,
      tableFilters,
      searchBy,
      getAccessTokenSilently,
      keycloak,
      apiUrl,
      fileName,
      isCsv: true,
      searchQuery,
      startDate,
      endDate,
      errorToastMessage: errorToastMessage,
      successToastMessage: successToastMessage,
      regenerationToastMessage: regenerationToastMessage,
      source: source ? source : undefined,
      paramIncluded,
    });
  };

  return (
    <Box
      sx={{
        border: `1px solid ${Colors.binge}`,
        borderRadius: 1.5,
        cursor: 'pointer',
        '&:hover': {
          borderWidth: '2px',
        },
      }}
      display="flex"
      width="36px"
      height="36px"
      justifyContent="center"
      alignItems="center"
      onClick={downloadHandler}
      data-testid={dataTestId}
    >
      <CustomSvgIcon
        sx={{
          width: '16px',
          height: '16px',
          color: Colors.binge,
          fontSize: FontSizes.fontSize14,
        }}
      >
        <StyledTooltip title="Download CSV">
          <CSVIcon />
        </StyledTooltip>
      </CustomSvgIcon>
    </Box>
  );
};
