import { TableColumnProps } from 'components/table/table-component/table-component';

export const assetsTableStructure: TableColumnProps[] = [
  {
    id: 'repo',
    title: 'Repository',
    position: 'left',
    withSort: true,
    noFilters: true,
    withSearch: true,
  },
  {
    id: 'repo_owner',
    title: 'Owner',
    position: 'left',
  },
  {
    id: 'source',
    title: 'Source',
    position: 'left',
  },
  // {
  //   id: 'highest_severity',
  //   title: 'Risk Rating',
  //   position: 'left',
  //   withSort: true,
  // },
  {
    id: 'visibility',
    title: 'Visibility',
    position: 'left',
    withSort: true,
    noFilters: true,
  },
  // {
  //   id: 'vulnerability_count',
  //   title: 'Total Vulns',
  //   position: 'left',
  //   withSort: true,
  //   noFilters: true,
  // },
  // {
  //   id: 'oss_count',
  //   title: '# SCA',
  //   position: 'left',
  //   withSort: true,
  //   noFilters: true,
  // },
  // {
  //   id: 'secret_count',
  //   title: '# Secrets',
  //   position: 'left',
  //   withSort: true,
  //   noFilters: true,
  // },
  // {
  //   id: 'sast_count',
  //   title: '# SAST',
  //   position: 'left',
  //   withSort: true,
  //   noFilters: true,
  // },
  // {
  //   id: 'iac_count',
  //   title: '# IaC',
  //   position: 'left',
  //   withSort: true,
  //   noFilters: true,
  // },
  {
    id: 'highest_permission',
    title: 'Permissions',
    position: 'left',
  },
  {
    id: 'permission_used',
    title: 'Permissions Used',
    position: 'left',
  },
  {
    id: 'teams',
    title: 'Teams',
    position: 'center',
  },
  // {
  //   id: 'last_activity',
  //   title: 'Last Activity',
  //   position: 'left',
  //   isDate: true,
  //   withSort: true,
  //   noFilters: true,
  // },
];

export const teamsTableStructure: TableColumnProps[] = [
  {
    id: 'team_name',
    title: 'Team Name',
  },
  {
    id: '',
    title: '',
  },
];
