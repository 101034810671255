import { InfoComponent } from 'components/info-component/info-component';
import { ModalContainer } from 'components/modals/modal-container/modal-container';
import { ApiContext } from 'components/providers/api-provider/api-provider';

import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import {
  PlainModals,
  closeModalByNameAction,
} from 'store/modules/modals/modals.reducer';
import {
  useGetModalMetaData,
  useIsPlainModalOpenSelector,
} from 'store/modules/modals/modals.selector';
import { advisoriesInfoStructure } from './constants';
import {
  Box,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
} from '@mui/material';
import { Colors } from 'constants/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactMarkdown from 'react-markdown';
import {
  OPENSOURCE_ADVISORIES_INFO_COMPONENT,
  OPENSOURCE_ADVISORIES_MODAL_ACCORDIAN,
} from 'constants/test-ids';
import { setApplicableFilters } from 'store/modules/selected-column/selected-column.reducer';
import { useSearchParams } from 'react-router-dom';
import { TableNamesOfFilters } from 'components/table/table-filters-components/filter-renderer/filter-renderer';

const severityOrder: any = {
  CRITICAL: 1,
  HIGH: 2,
  MODERATE: 3,
  LOW: 4,
};

export const AdvisoriesModal = () => {
  const dispatch = useDispatch();
  const metadata = useGetModalMetaData();
  const apiContext = useContext(ApiContext);
  const [searchParams] = useSearchParams();
  const criticalAndHigh = searchParams.get('candh') as string;

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const { pkg, version, pkgManager } = metadata;

  const isOpenModal = useIsPlainModalOpenSelector(PlainModals.ADVISORIES_MODAL);

  const handleCloseModal = () => {
    if (criticalAndHigh) {
      setTimeout(
        () =>
          dispatch(
            setApplicableFilters({
              table: TableNamesOfFilters.ASSET_EXTERNAL_PACKAGES,
              applicableFilters: { severity: ['CRITICAL', 'HIGH'] },
            }),
          ),
        5,
      );
    }

    dispatch(closeModalByNameAction(PlainModals.ADVISORIES_MODAL));
  };

  const sortAdvisories = (advisories: any) =>
    advisories.sort(
      (a: any, b: any) => severityOrder[a.severity] - severityOrder[b.severity],
    );

  const { data, isLoading, isError } =
    apiContext.baseApi.useGetExternalPackageVulnerabilityDetailsQuery({
      pkg,
      version,
      pkgManager,
    });

  // const renderSeverityCell = (item: any) => {
  //   let severity = 0;
  //   switch (item.severity) {
  //     case 'CRITICAL':
  //       severity = 5;
  //       break;
  //     case 'HIGH':
  //       severity = 4;
  //       break;
  //     case 'MODERATE':
  //       severity = 3;
  //       break;
  //     case 'LOW':
  //       severity = 2;
  //       break;
  //     case 'INFO':
  //       severity = 1;
  //       break;
  //     default:
  //       severity = 0;
  //       break;
  //   }
  //   return <SeverityIcon severityScore={severity} iconSize="20px" />;
  // };

  const renderSeverityCell = (item: any) => {
    let chipColor = '';
    let chipBackground = '';
    switch (item.severity?.toLocaleUpperCase()) {
      case 'CRITICAL':
        chipColor = Colors.critical;
        chipBackground = Colors.criticalBg;
        break;
      case 'HIGH':
        chipColor = Colors.high;
        chipBackground = Colors.highBg;

        break;
      case 'MODERATE':
        chipColor = Colors.medium;
        chipBackground = Colors.mediumBg;

        break;
      case 'LOW':
        chipColor = Colors.low;
        chipBackground = Colors.lowBg;
        break;
      default:
        chipColor = Colors.low;
        chipBackground = Colors.lowBg;
        break;
    }
    return (
      <Chip
        size="small"
        sx={{
          backgroundColor: chipBackground,
          color: chipColor,
          border: `1.8px solid ${chipBackground}`,
          px: 1,
        }}
        label={item.severity?.toLocaleUpperCase()}
        variant="outlined"
      />
    );
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <ModalContainer
      isOpenModal={isOpenModal}
      handleCloseModal={handleCloseModal}
      title="Package Vulnerabilities Details"
      modalWidth="850px"
      modalHeight="600px"
      alignItems="start"
      left="20%"
    >
      <Box sx={{ height: '100%', width: '100%' }}>
        <InfoComponent
          data={data}
          isLoading={isLoading}
          isError={isError}
          infoStructure={advisoriesInfoStructure}
          marginBottom="0px"
          dataTestId={OPENSOURCE_ADVISORIES_INFO_COMPONENT}
        />
        {/* <TableComponent
          tableId={tableId}
        isListLoading={isLoading}
        isListErrored={isError}
        isListFetching={isFetching}
        isEmptyList={!data?.advisories?.length}
        list={data?.advisories}
        tableColumns={advisoriesTableStructure}
        extraCellsRenderObj={extraCellsRenderObj}
        notPaginated
      /> */}

        {/* <Box marginTop={'10px'}>
        <Typography
          fontSize={FontSizes.fontSize14}
          color={Colors.whiteTransparent07}
        >
          Summary
        </Typography>
        <Typography fontSize={FontSizes.fontSize16} color={Colors.white}>
          {data?.summary || ''}
        </Typography>
      </Box>

      <Box marginTop={'10px'}>
        <Typography
          fontSize={FontSizes.fontSize14}
          color={Colors.whiteTransparent07}
        >
          Fixed Version
        </Typography>
        <Typography fontSize={FontSizes.fontSize16} color={Colors.white}>
          {data?.fixed_version || ''}
        </Typography>
      </Box> */}
        <Box
          sx={{
            width: '100%',
            overflowY: 'auto',
            height: '300px',
            marginTop: '20px',
          }}
        >
          {sortAdvisories(data?.advisories ? [...data.advisories] : []).map(
            (advisory: any, index: number) => (
              <Accordion
                key={index}
                expanded={expanded === advisory?.CVE}
                onChange={handleChange(advisory?.CVE)}
                sx={{ maxWidth: '100%', width: '100%', marginBottom: '10px' }}
                data-testid={OPENSOURCE_ADVISORIES_MODAL_ACCORDIAN}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon sx={{ color: Colors.blackPearl }} />
                  }
                  aria-controls={`panel${index + 1}bh-content`}
                  id={`panel${index + 1}bh-header`}
                  sx={{
                    backgroundColor:
                      index % 2 === 0 ? Colors.tableCell : Colors.white,
                    '&:hover': {
                      backgroundColor: Colors.tableHover,
                    },
                    width: '100%',
                  }}
                >
                  <Typography
                    mr={2}
                    color={Colors.blackPearl}
                    width={'15%'}
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  >
                    {renderSeverityCell(advisory)}
                  </Typography>
                  <Typography mr={2} color={Colors.blackPearl} width={'16%'}>
                    {advisory?.CVE}
                  </Typography>
                  <Typography mr={2} color={Colors.blackPearl} width={'42.5%'}>
                    {advisory?.summary}
                  </Typography>
                  <Typography color={Colors.blackPearl} width={'26.5%'}>
                    Fixed Version:{' '}
                    <span style={{ fontWeight: 500 }}>
                      {advisory?.fixed ? advisory.fixed : 'No fix found'}
                    </span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    background: Colors.sectionBackground,
                    color: Colors.blackPearl,
                  }}
                >
                  <ReactMarkdown
                    children={advisory?.details ? advisory?.details : ''}
                  />
                </AccordionDetails>
              </Accordion>
            ),
          )}
        </Box>
      </Box>
    </ModalContainer>
  );
};
