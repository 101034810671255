import { FilterItem } from 'components/multi-filter/multi-filter';
import { TableColumnProps } from 'components/table/table-component/table-component';

export const tableStructureWithLogin: TableColumnProps[] = [
  {
    id: 'policy_name',
    title: 'Policy',
    position: 'left',
    // withSort: true,
    // withSearch: true,
    // noFilters: true,
  },
  {
    id: 'status',
    title: 'Status',
    position: 'left',
    // withSort: true,
    // withSearch: true,
    // noFilters: true,
  },
  {
    id: 'severity',
    title: 'Severity',
    position: 'left',
    // withSort: true,
    // noFilters: true,
  },
  {
    id: 'reason',
    title: 'Reason',
    position: 'left',
    // withSort: true,
    // withSearch: true,
    // noFilters: true,
  },
];

export const AssetContributorsFilterList: FilterItem[] = [
  {
    title: 'Is owner',
    filterId: 'only_owners',
    filterColumns: ['user_id'],
    props: [
      {
        label: 'True',
        value: true,
      },
      {
        label: 'False',
        value: false,
      },
    ],
  },
  {
    title: 'Is overprivilege',
    filterId: 'only_overprivilege',
    filterColumns: ['user_id'],
    props: [
      {
        label: 'True',
        value: true,
      },
      {
        label: 'False',
        value: false,
      },
    ],
  },
];
