import { FilterItem } from 'components/multi-filter/multi-filter';
import { TableColumnProps } from 'components/table/table-component/table-component';

export const tableStructureWithLogin: TableColumnProps[] = [
  {
    id: 'email',
    title: 'Identity',
    position: 'left',
    // withSort: true,
    // withSearch: true,
    // noFilters: true,
  },
  {
    id: 'aws_policy_name',
    title: 'AWS Policy Name',
    position: 'left',
    // withSort: true,
    // withSearch: true,
    // noFilters: true,
  },
  {
    id: 'last_access_date',
    title: 'Last Access Date',
    position: 'left',
    // withSort: true,
    // noFilters: true,
  },
  {
    id: 'access_levels',
    title: 'Access Level',
    position: 'left',
    // withSort: true,
    // withSearch: true,
    // noFilters: true,
  },
];

export const AssetContributorsFilterList: FilterItem[] = [
  {
    title: 'Is owner',
    filterId: 'only_owners',
    filterColumns: ['user_id'],
    props: [
      {
        label: 'True',
        value: true,
      },
      {
        label: 'False',
        value: false,
      },
    ],
  },
  {
    title: 'Is overprivilege',
    filterId: 'only_overprivilege',
    filterColumns: ['user_id'],
    props: [
      {
        label: 'True',
        value: true,
      },
      {
        label: 'False',
        value: false,
      },
    ],
  },
];
