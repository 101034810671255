import { TableColumnProps } from 'components/table/table-component/table-component';

export const orgMembersTableStructure: TableColumnProps[] = [
  {
    id: 'name',
    title: 'Member',
    position: 'left',
  },
  {
    id: 'email',
    title: 'Email',
    position: 'left',
  },
  {
    id: 'vuln_counts',
    title: 'Vulnerabilities',
    position: 'left',
  },
  {
    id: 'oss',
    title: 'SCA',
    position: 'left',
  },
  {
    id: 'secret',
    title: 'Secrets',
    position: 'left',
  },
  {
    id: 'sast',
    title: 'SAST',
    position: 'left',
  },
  {
    id: 'dast',
    title: 'DAST',
    position: 'left',
  },
  {
    id: 'iac',
    title: 'IAC',
    position: 'left',
  },
  {
    id: 'cspm',
    title: 'CSPM',
    position: 'left',
  },
  {
    id: 'Container',
    title: 'Container',
    position: 'left',
  },
];

export const orgDirectMembersTableStructure: TableColumnProps[] = [
  {
    id: 'display_name',
    title: 'Member',
    position: 'left',
  },
  {
    id: 'risk_rating',
    title: 'Risk Rating',
    position: 'left',
  },
  {
    id: 'overprivilege_score',
    title: 'Overprivilege Score',
    position: 'left',
  },
  // {
  //   id: 'entity_type',
  //   title: 'Entity',
  //   position: 'left',
  // },
  // {
  //   id: 'manager_name',
  //   title: 'Manager',
  //   position: 'left',
  // },
  {
    id: 'secret_counts',
    title: 'Secrets',
    position: 'left',
  },
  {
    id: 'oss_counts',
    title: 'SCA',
    position: 'left',
  },
  {
    id: 'sast_counts',
    title: 'SAST',
    position: 'left',
  },
  {
    id: 'dast_counts',
    title: 'DAST',
    position: 'left',
  },
  {
    id: 'iac_counts',
    title: 'IAC',
    position: 'left',
  },
  {
    id: 'cspm_counts',
    title: 'CSPM',
    position: 'left',
  },
  {
    id: 'container_counts',
    title: 'Container',
    position: 'left',
  },
  {
    id: 'registry_counts',
    title: 'Registry',
    position: 'left',
  },
];

export const vulnTableColumns: TableColumnProps[] = [
  {
    id: 'vulnerability',
    title: 'Vulnerability',
    position: 'left',
  },
  {
    id: 'count',
    title: 'Count',
    position: 'right',
  },
];
