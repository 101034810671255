import { TableColumnProps } from '../../../../components/table/table-component/table-component';

export const policyTableStructure: TableColumnProps[] = [
  {
    id: 'name',
    title: 'Policy',
    position: 'left',
    withSort: true,
    noFilters: true,
  },
  {
    id: 'count',
    title: 'Detections',
    position: 'left',
    withSort: true,
  },
  {
    id: 'severity',
    title: 'Risk Rating',
    withSort: true,
    position: 'left',
  },
  {
    id: 'actions',
    title: '',
    position: 'center',
  },
];

export const policyFilterList = (custom: boolean) => [
  ...(custom
    ? [
        {
          title: 'Activated',
          filterId: 'activated',
          filterColumns: ['name'],
          props: [
            {
              label: 'Activated',
              value: 'true',
            },
            {
              label: 'Not Activated',
              value: 'false',
            },
          ],
        },
      ]
    : []),
  {
    title: 'Severity',
    filterId: 'severity',
    filterColumns: ['severity'],
    props: [
      {
        label: 'Critical',
        value: '5',
      },
      {
        label: 'High',
        value: '4',
      },
      {
        label: 'Moderate',
        value: '3',
      },
      {
        label: 'Low',
        value: '2',
      },
      {
        label: 'Informational',
        value: '1',
      },
    ],
  },
  {
    title: 'With Detections',
    filterId: 'detections',
    filterColumns: ['count'],
    props: [
      {
        label: 'True',
        value: 'true',
      },
      {
        label: 'False',
        value: 'false',
      },
    ],
  },
  // {
  //   title: 'Frequency',
  //   filterId: 'frequency',
  //   filterColumns: ['name'],
  //   props: [
  //     {
  //       label: 'Real Time',
  //       value: 'real time',
  //     },
  //     {
  //       label: 'Daily',
  //       value: 'daily',
  //     },
  //     {
  //       label: 'Weekly',
  //       value: 'weekly',
  //     },
  //     {
  //       label: 'Monthly',
  //       value: 'monthly',
  //     },
  //   ],
  // },
];
