import { TableColumnProps } from 'components/table/table-component/table-component';

export const RegistryTableStructure: TableColumnProps[] = [
  {
    id: 'cve',
    title: 'CVE',
    withSort: true,
    noFilters: true,
    position: 'left',
  },
  {
    id: 'id',
    title: 'Image',
    withSort: true,
    noFilters: true,
    position: 'left',
  },
  {
    id: 'package',
    title: 'Package',
    withSort: true,
    noFilters: true,
    position: 'left',
  },
  {
    id: 'discovered_date',
    title: 'Discovered',
    withSort: true,
    noFilters: true,
    isDate: true,
    position: 'left',
  },
  {
    id: 'severity',
    title: 'Severity',
    withSort: true,
    noFilters: true,
    position: 'left',
  },
  {
    id: 'user',
    title: 'User',
    withSort: true,
    noFilters: true,
    position: 'left',
  },
  {
    id: 'view_more',
    title: '',
    position: 'left',
  },
];
