import { TableColumnProps } from 'components/table/table-component/table-component';

export const cloudAlertsTableStructure: TableColumnProps[] = [
  {
    id: 'policy_name',
    title: 'Policy Name',
    position: 'left',
  },
  {
    id: 'resource_name',
    title: 'Resource Name',
    position: 'left',
  },
  {
    id: 'account_id',
    title: 'Account Id',
    position: 'left',
  },
  {
    id: 'account_name',
    title: 'Account Name',
    position: 'left',
  },
  {
    id: 'cloudAccountOwners',
    title: 'Owner',
    position: 'left',
  },
];
