import { TableColumnProps } from 'components/table/table-component/table-component';

export const orgDetailsTeamsTableStructure: TableColumnProps[] = [
  {
    id: 'display_name',
    title: 'Department',
    position: 'left',
  },
  {
    id: 'manager',
    title: 'Email',
    position: 'left',
  },
  // {
  //   id: 'manager_name',
  //   title: 'Manager',
  //   position: 'left',
  // },
  {
    id: 'secret_counts',
    title: 'Secrets',
    position: 'left',
  },
  {
    id: 'oss_counts',
    title: 'SCA',
    position: 'left',
  },
  {
    id: 'sast_counts',
    title: 'SAST',
    position: 'left',
  },
  {
    id: 'dast_counts',
    title: 'DAST',
    position: 'left',
  },
  {
    id: 'iac_counts',
    title: 'IAC',
    position: 'left',
  },
  {
    id: 'cspm_counts',
    title: 'CSPM',
    position: 'left',
  },
  {
    id: 'container_counts',
    title: 'Container',
    position: 'left',
  },
  {
    id: 'registry_counts',
    title: 'Registry',
    position: 'left',
  },
];
