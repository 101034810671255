import githubImg from 'assets/icons/github.svg';
import azureDevopsImg from 'assets/icons/azuredevops.svg';
import jiraImg from 'assets/icons/jira.svg';
import googleDirectoryImg from 'assets/icons/google-directory.svg';
import jenkinsImg from 'assets/icons/jenkins.svg';
import oktaImg from 'assets/icons/okta.svg';
import slackImg from 'assets/icons/slack.svg';
import gitlabImg from 'assets/icons/gitlab.svg';
import azureAdImg from 'assets/icons/azure-ad.svg';
import bitbucketImg from 'assets/icons/bitbucket.svg';
import circleCiImg from 'assets/icons/circle-ci.svg';
import jfrogImg from 'assets/icons/jfrog.svg';
import emailImg from 'assets/icons/email-policy-builder.svg';
import splunkImg from 'assets/icons/splunk.svg';
import teamsImg from 'assets/icons/microsoft-teams.svg';
import flockImg from 'assets/icons/flock.svg';
import snykImg from 'assets/icons/snyk.svg';
import dockerImg from 'assets/icons/docker.svg';
import blackduckImg from 'assets/icons/blackduck.svg';
import compassImg from 'assets/icons/compass.svg';
import ecrImg from 'assets/icons/aws-ecr.svg';
import webhookImg from 'assets/icons/webhook.svg';
import sonarqubeImg from 'assets/icons/sonarqube.svg';
import prismaImg from 'assets/icons/prisma.svg';
import harnessImg from 'assets/icons/harness.svg';
import invictiImg from 'assets/icons/invicti.svg';
import bambooImg from 'assets/icons/bamboo.svg';
import wizImg from 'assets/icons/wiz.svg';
import { InputsSchemas } from 'pages/connectors/types';
import { AssetsRepositoriesItemRes } from 'store/apis/types';

export const getIntegrationImage = (source: InputsSchemas | '') => {
  switch (source) {
    case InputsSchemas.GITHUB:
      return githubImg;
    case InputsSchemas.GITLAB:
      return gitlabImg;
    case InputsSchemas.OKTA:
      return oktaImg;
    case InputsSchemas.IDSCIENCE_EMAIL:
      return emailImg;
    case InputsSchemas.JIRA:
      return jiraImg;
    case InputsSchemas.AZURE_AD:
      return azureAdImg;
    case InputsSchemas.COMPASS:
      return compassImg;
    case InputsSchemas.BITBUCKET:
      return bitbucketImg;
    case InputsSchemas.JENKINS:
      return jenkinsImg;
    case InputsSchemas.CIRCLE_CI:
      return circleCiImg;
    case InputsSchemas.AZURE_DEVOPS:
      return azureDevopsImg;
    case InputsSchemas.GOOGLE_DIR:
      return googleDirectoryImg;
    case InputsSchemas.SLACK:
      return slackImg;
    case InputsSchemas.JFROG:
      return jfrogImg;
    case InputsSchemas.BLACKDUCK:
      return blackduckImg;
    case InputsSchemas.FLOCK:
      return flockImg;
    case InputsSchemas.TEAMS:
      return teamsImg;
    case InputsSchemas.EMAIL:
      return emailImg;
    case InputsSchemas.SPLUNK:
      return splunkImg;
    case InputsSchemas.SPLUNK_INBOUND:
      return splunkImg;
    case InputsSchemas.SNYK:
      return snykImg;
    case InputsSchemas.DOCKERHUB:
      return dockerImg;
    case InputsSchemas.ECR:
      return ecrImg;
    case InputsSchemas.WEBHOOK:
      return webhookImg;
    case InputsSchemas.FORGE_BITBUCKET:
      return bitbucketImg;
    case InputsSchemas.SONARQUBE:
      return sonarqubeImg;
    case InputsSchemas.PRISMA:
      return prismaImg;
    case InputsSchemas.HARNESS:
      return harnessImg;
    case InputsSchemas.INVICTI:
      return invictiImg;
    case InputsSchemas.BAMBOO:
      return bambooImg;
    case InputsSchemas.WIZ:
      return wizImg;
    default:
      return githubImg;
  }
};

export const getRepositoryStatus = (
  item: AssetsRepositoriesItemRes,
): string => {
  if (item.archived) return 'Archived';
  if (item.disabled) return 'Disabled';
  if (item.inactive) return 'Stale';
  return 'Active';
};

export const getIntegrationName = (source: InputsSchemas | '') => {
  switch (source) {
    case InputsSchemas.GITHUB:
      return 'GitHub';
    case InputsSchemas.GITLAB:
      return 'GitLab';
    case InputsSchemas.OKTA:
      return 'Okta';
    case InputsSchemas.IDSCIENCE_EMAIL:
      return 'BlueFlagSecurity Email';
    case InputsSchemas.JIRA:
      return 'Jira';
    case InputsSchemas.AZURE_AD:
      return 'Azure AD';
    case InputsSchemas.COMPASS:
      return 'Compass';
    case InputsSchemas.BITBUCKET:
      return 'BitBucket';
    case InputsSchemas.JENKINS:
      return 'Jenkins';
    case InputsSchemas.CIRCLE_CI:
      return 'Circle CI';
    case InputsSchemas.AZURE_DEVOPS:
      return 'Azure DevOps';
    case InputsSchemas.GOOGLE_DIR:
      return 'Google Directory';
    case InputsSchemas.SLACK:
      return 'Slack';
    case InputsSchemas.JFROG:
      return 'JFrog';
    case InputsSchemas.BLACKDUCK:
      return 'BlackDuck';
    case InputsSchemas.FLOCK:
      return 'Flock';
    case InputsSchemas.TEAMS:
      return 'Teams';
    case InputsSchemas.EMAIL:
      return 'Email';
    case InputsSchemas.SPLUNK:
      return 'Splunk';
    case InputsSchemas.SNYK:
      return 'Snyk';
    case InputsSchemas.DOCKERHUB:
      return 'DockerHub';
    case InputsSchemas.ECR:
      return 'AWS ECR';
    case InputsSchemas.BAMBOO:
      return 'Bamboo';
    case InputsSchemas.WIZ:
      return 'Wiz';
    default:
      return '';
  }
};
