import { TableColumnProps } from 'components/table/table-component/table-component';

export const orgVulnerabilityReportTableStructure: TableColumnProps[] = [
  {
    id: 'name',
    title: 'Department',
    position: 'left',
  },
  {
    id: 'email',
    title: 'Email',
    position: 'left',
    noFilters: true,
  },
  {
    id: 'oss',
    title: 'SCA',
    position: 'left',
    noFilters: true,
  },
  {
    id: 'secret',
    title: 'Secrets',
    position: 'left',
    noFilters: true,
  },
  {
    id: 'sast',
    title: 'SAST',
    position: 'left',
    noFilters: true,
  },
  {
    id: 'dast',
    title: 'DAST',
    position: 'left',
    noFilters: true,
  },
  {
    id: 'iac',
    title: 'IaC',
    position: 'left',
    noFilters: true,
  },
  {
    id: 'cspm',
    title: 'CSPM',
    position: 'left',
    noFilters: true,
  },
  {
    id: 'container',
    title: 'Container',
    position: 'left',
    noFilters: true,
  },
  {
    id: 'registry',
    title: 'Registry',
    position: 'left',
    noFilters: true,
  },
];
