import React, { useRef, useState } from 'react';
import { Box, RadioGroup, Divider } from '@mui/material';
import { useDispatch } from 'react-redux';

import { useClickOutsideToClose } from 'hooks/use-click-outside-to-close';
import {
  FiltersContainer,
  SectionTitle,
  PopupWrapper,
  FilterSection,
  StyledCheckbox,
  StyledFormControl,
  StyledFormControlLabel,
  StyledRadio,
} from './styles';
import { Filter } from 'store/modules/filters/filters.reducer';
import { StyledButton } from '../button/button';
import { Colors } from 'constants/colors';
import { setSelectedColumn } from 'store/modules/selected-column/selected-column.reducer';
import { useSelectedColumnSelector } from 'store/modules/selected-column/selected-column.selector';
import { SearchInput } from 'components/table/table-filters-components/search-input/search-input';

export interface FilterItem {
  title: string;
  filterId: string;
  isMultiSelect?: boolean;
  props: {
    label: string;
    value: string | boolean;
  }[];
  filterColumns?: string[];
}

interface Props {
  tableId?: string;
  isLast: boolean;
  removeFiltersHandler: () => void;
  showResultsHandler: () => void;
  setFilterHandler: (
    filter: string,
    value?: string | boolean | string[],
  ) => void;
  filtersObj: Filter;
  filters: FilterItem[];
  horizontal?: boolean;
  currentOrder?: string;
  setCurrentOrder?: React.Dispatch<React.SetStateAction<string>>;
  search?: string;
  setSearch?: (search: string) => void;
}

export const MultiFilter = ({
  isLast,
  tableId = '',
  removeFiltersHandler,
  showResultsHandler,
  setFilterHandler,
  filtersObj,
  filters,
  horizontal,
  currentOrder,
  setCurrentOrder,
  setSearch,
  search,
}: Props) => {
  const dispatch = useDispatch();
  const selectedColumnFilter = useSelectedColumnSelector(tableId);

  const { tableFilterChips } = selectedColumnFilter;
  const { filterTitles } = tableFilterChips;
  const [isActive, setIsActive] = useState(
    selectedColumnFilter?.previousColumn ? true : false,
  );
  const bodyRef = useRef<HTMLDivElement>(null);
  const filterHandler = (
    filter: string,
    value?: string | boolean,
    isMulti?: boolean,
  ) => {
    if (isMulti) {
      let newFieldValue: string[] = filtersObj[filter]
        ? // @ts-ignore
          [...filtersObj[filter]]
        : [];

      if (newFieldValue.includes(value as string)) {
        // @ts-ignore
        newFieldValue = filtersObj?.[filter]?.filter(
          (item: string) => item !== value,
        );
      } else {
        if (typeof value === 'string') {
          newFieldValue.push(value);
        }
      }

      setFilterHandler(
        filter,
        newFieldValue.length ? newFieldValue : undefined,
      );
    } else {
      setFilterHandler(filter, value);
    }
  };

  const closePopup = () => {
    setIsActive(false);
    dispatch(
      setSelectedColumn({
        table: tableId,
        column: '',
      }),
    );
  };

  const resetButtonColor = () => {
    const activeColumn = selectedColumnFilter?.previousColumn;
    if (setSearch && typeof search === 'string' && search) return Colors.binge;
    if (
      tableFilterChips?.sorting_field === activeColumn ||
      (filterTitles &&
        Array.isArray(filterTitles) &&
        filterTitles.some((col) => col.column === activeColumn))
    )
      return Colors.binge;
    return Colors.darkDesaturatedCyan;
  };

  useClickOutsideToClose(bodyRef, closePopup);

  return (
    <PopupWrapper
      ref={bodyRef}
      horizontal={horizontal}
      x={selectedColumnFilter?.coordinates?.x}
      y={selectedColumnFilter?.coordinates?.y}
      isLast={isLast}
      sx={{
        display: isActive ? 'visible' : 'none',
        textTransform: 'none',
        border: `1px solid ${Colors.tableBorder}`,
        minWidth: '430px',
      }}
      columnName={selectedColumnFilter.previousTable}
    >
      <FiltersContainer horizontal={horizontal}>
        {setCurrentOrder && (
          <FilterSection>
            <Box
              display="flex"
              flexDirection={'row'}
              gap={'10px'}
              alignItems={'center'}
            >
              <SectionTitle
                sx={{
                  pt: '3px',
                  margin: 0,
                }}
              >
                Sort
              </SectionTitle>
              <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <StyledFormControl
                    sx={{
                      py: 0.5,
                      border: `1.5px solid ${
                        currentOrder === 'asc'
                          ? Colors.binge
                          : Colors.blackPearl
                      }`,
                      pl: 1,
                      borderRadius: 2,
                      mr: 2,
                    }}
                  >
                    <StyledFormControlLabel
                      control={
                        <StyledRadio
                          checked={currentOrder === 'asc'}
                          onClick={() =>
                            setCurrentOrder(currentOrder === 'asc' ? '' : 'asc')
                          }
                        />
                      }
                      checked={currentOrder === 'asc'}
                      label="Ascending"
                    />
                  </StyledFormControl>
                  <StyledFormControl
                    sx={{
                      py: 0.5,
                      border: `1.5px solid ${
                        currentOrder === 'des'
                          ? Colors.binge
                          : Colors.blackPearl
                      }`,
                      pl: 1,
                      borderRadius: 2,
                    }}
                  >
                    <StyledFormControlLabel
                      control={
                        <StyledRadio
                          checked={currentOrder === 'des'}
                          onClick={() =>
                            setCurrentOrder(currentOrder === 'des' ? '' : 'des')
                          }
                        />
                      }
                      checked={currentOrder === 'des'}
                      label="Descending"
                    />
                  </StyledFormControl>
                </Box>
              </Box>
              <StyledButton
                size="large"
                variant="text"
                sx={{
                  float: 'right',
                  color: `${
                    currentOrder ? Colors.binge : Colors.whiteTransparent07
                  } !important`,
                }}
                color="secondary"
                onClick={() => setCurrentOrder('')}
              >
                Clear
              </StyledButton>
            </Box>
            <Divider
              sx={{
                background: Colors.tableBorder,
                mt: '15px',
                mb: '15px',
              }}
            />
          </FilterSection>
        )}
        {setSearch && typeof search === 'string' && (
          <FilterSection>
            <Box>
              <SearchInput
                searchQuery={search}
                setSearchQuery={setSearch}
                dataTestId={''}
                width="320px"
              />
            </Box>
            <Divider
              sx={{
                background: Colors.tableBorder,
                mt: '15px',
                mb: '15px',
              }}
            />
          </FilterSection>
        )}
        {filters.map((filter) => (
          <FilterSection key={filter.filterId}>
            <Box
              display="flex"
              flexDirection={'row'}
              gap={'10px'}
              alignItems={'center'}
            >
              <SectionTitle>{filter.title}</SectionTitle>
              <Box
                sx={{
                  display: 'flex',
                  maxWidth: '45%',
                  justifyContent: 'space-between',
                }}
              >
                <RadioGroup row name="row-radio-buttons-group">
                  {filter.props.map((prop, index) => (
                    <StyledFormControl key={prop.label}>
                      {filter.isMultiSelect ? (
                        <StyledFormControlLabel
                          control={
                            <StyledCheckbox
                              onChange={() =>
                                filterHandler(filter.filterId, prop.value, true)
                              }
                              checked={
                                //@ts-ignore
                                filtersObj[filter.filterId]?.includes(
                                  prop.value,
                                ) || false
                              }
                            />
                          }
                          label={prop.label}
                        />
                      ) : (
                        <StyledFormControlLabel
                          control={
                            <StyledRadio
                              checked={
                                filtersObj[filter.filterId] === prop.value
                              }
                              onClick={() =>
                                filterHandler(filter.filterId, prop.value)
                              }
                              sx={{ ml: 1 }}
                            />
                          }
                          checked={filtersObj[filter.filterId] === prop.value}
                          label={prop.label}
                        />
                      )}
                    </StyledFormControl>
                  ))}
                </RadioGroup>
              </Box>
              {filtersObj[filter.filterId] ||
              filtersObj[filter.filterId] === false ? (
                <StyledButton
                  size="large"
                  variant="text"
                  sx={{
                    float: 'right',
                    color: `${Colors.binge} !important`,
                  }}
                  color="secondary"
                  onClick={() => {
                    const filterName: string = filter.filterId;
                    setFilterHandler(
                      filterName,
                      //@ts-ignore
                      filtersObj[filterName],
                    );
                  }}
                >
                  Clear
                </StyledButton>
              ) : null}
            </Box>
            <Divider
              sx={{
                background: Colors.tableBorder,
                mt: '15px',
                mb: '15px',
              }}
            />
          </FilterSection>
        ))}
      </FiltersContainer>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <StyledButton
            size="large"
            variant="text"
            color="info"
            sx={{ color: `${resetButtonColor()} !important` }}
            onClick={() => {
              removeFiltersHandler();
              closePopup();
            }}
            disabled={resetButtonColor() === Colors.darkDesaturatedCyan}
          >
            Reset
          </StyledButton>
        </Box>
        <Box display="flex" gap={2}>
          <StyledButton
            size="large"
            variant="outlined"
            color="info"
            onClick={() => {
              closePopup();
            }}
          >
            Cancel
          </StyledButton>
          <StyledButton
            size="large"
            variant="contained"
            color="secondary"
            onClick={() => {
              showResultsHandler();
              closePopup();
            }}
          >
            Apply
          </StyledButton>
        </Box>
      </Box>
    </PopupWrapper>
  );
};
