import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { SortOrder } from 'utils/sort-order/sort';

export interface TeamsState {
  activeSortedField: string;
  activeSortedOrder: SortOrder;
  searchQuery: string;
  activeTab: number;
  teamTab: number;
  orgTeamTab: number;
  orgMemberTab: number;
  orgVulnTab: number;
  orgCloudVulnTab: number;
}

const initialState: TeamsState = {
  activeSortedField: 'team_risk_score',
  activeSortedOrder: SortOrder.Desc,
  searchQuery: '',
  activeTab: 0,
  teamTab: 0,
  orgTeamTab: 0,
  orgMemberTab: 0,
  orgVulnTab: 0,
  orgCloudVulnTab: 0,
};

export const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    setActiveSortedField: (
      state: TeamsState,
      action: PayloadAction<string>,
    ) => {
      state.activeSortedField =
        action.payload || initialState.activeSortedField;
    },
    setActiveSortedOrder: (
      state: TeamsState,
      action: PayloadAction<SortOrder>,
    ) => {
      state.activeSortedOrder = action.payload;
    },
    setSearchQuery: (state: TeamsState, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
    setActiveTab: (state: TeamsState, action: PayloadAction<number>) => {
      state.activeTab = action.payload;
    },
    setTeamTab: (state: TeamsState, action: PayloadAction<number>) => {
      state.teamTab = action.payload;
    },
    setOrgTeamTab: (state: TeamsState, action: PayloadAction<number>) => {
      state.orgTeamTab = action.payload;
    },
    setOrgMemberTab: (state: TeamsState, action: PayloadAction<number>) => {
      state.orgMemberTab = action.payload;
    },
    setOrgVulnTab: (state: TeamsState, action: PayloadAction<number>) => {
      state.orgVulnTab = action.payload;
    },
    setOrgCloudVulnTab: (state: TeamsState, action: PayloadAction<number>) => {
      state.orgCloudVulnTab = action.payload;
    },
  },
});

export const {
  setActiveSortedField,
  setActiveSortedOrder,
  setSearchQuery,
  setActiveTab,
  setTeamTab,
  setOrgTeamTab,
  setOrgMemberTab,
  setOrgVulnTab,
  setOrgCloudVulnTab,
} = teamsSlice.actions;

export const teamsReducer = teamsSlice.reducer;
