import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Input,
  styled,
  TextareaAutosize,
  TextField,
} from '@mui/material';

import { Colors } from 'constants/colors';
import { FontSizes } from 'constants/font-sizes';

export const StyledFormControl = styled(FormControl)(() => ({
  paddingLeft: '0',
}));

export const StyledFormLabel = styled(FormLabel)(() => ({
  fontSize: FontSizes.fontSize13,
  fontWeight: '600',
  lineHeight: '12px',
  color: Colors.blackPearl,

  '&.Mui-focused': {
    color: Colors.blackPearl,
  },
}));

export const StyledFormHelperText = styled(FormHelperText)(() => ({
  fontSize: FontSizes.fontSize13,
  fontWeight: '600',
  lineHeight: '24px',
  color: Colors.blackPearl,
  opacity: '0.53',
  marginLeft: '0',
}));

export const StyledInput = styled(Input)(() => ({
  fontSize: FontSizes.fontSize13,
  lineHeight: '24px',
  backgroundColor: 'transparent',
  color: Colors.blackPearl,
  width: '40%',

  '& .Mui-disabled': {
    color: Colors.blackPearl,
    WebkitTextFillColor: Colors.blackPearl,
  },

  '& .MuiInput-root:after': {
    borderBottom: `1px solid ${Colors.darkDesaturatedCyan}`,
    transform: 'none',
    transition: 'none',
  },

  '& .MuiInput-root.Mui-error:after': {
    borderColor: Colors.red,
  },

  '& .MuiInput-underline': {
    '&:hover:before': {
      borderBottom: `1px solid ${Colors.darkDesaturatedCyan}`,
    },
  },

  '& .Mui-focused': {
    borderColor: Colors.darkDesaturatedCyan,
  },
}));

export const StyledTextField = styled(TextField)({
  borderColor: Colors.blackPearl,
  '& label': {
    color: Colors.darkGray,
  },
  '& label.Mui-focused': {
    color: '#A0AAB4',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#B2BAC2',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#B2BAC2',
    },
    '&:hover fieldset': {
      borderColor: Colors.darkGray,
    },
    '&.Mui-focused fieldset': {
      borderColor: Colors.black,
    },
  },
});

export const StyledCheckbox = styled(Checkbox)(() => ({
  color: Colors.darkGray,

  '& .Mui-checked': {
    color: Colors.blackPearl,
  },
  '& .MuiCheckbox-input': {
    borderRadius: '3px',
    width: '14px',
    height: '14px',
    border: '1px solid #6F8198',
  },
}));

export const StyledFormControlLabel = styled(FormControlLabel)(
  ({ checked }: { checked?: boolean }) => ({
    '& .MuiFormControlLabel-label': {
      fontSize: FontSizes.fontSize12,
      lineHeight: checked === undefined || checked ? '12px' : '16px',
      color: Colors.blackPearl,
    },
  }),
);

export const StyledTextArea = styled(TextareaAutosize)<{ error: boolean }>(
  ({ error }) => ({
    backgroundColor: Colors.tableHeader,
    color: Colors.blackPearl,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderBottomColor: error ? Colors.red : Colors.darkDesaturatedCyan,
    outline: 'none',
    resize: 'vertical',
    maxHeight: '300px',
  }),
);
