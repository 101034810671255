import { ReactNode } from 'react';
import {
  Box,
  Skeleton,
  SxProps,
  TableCell,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { ReactComponent as ExternalIcon } from 'assets/icons/external.svg';
import { ReactComponent as InternalIcon } from 'assets/icons/internal.svg';

import { Colors } from 'constants/colors';
import { FontSizes } from 'constants/font-sizes';
import { fontSecondary } from 'constants/font-family';

interface SpinnerRowProps {
  colCount: number;
  rows?: number;
}

export const TableLoader = ({ colCount, rows }: SpinnerRowProps) => {
  return (
    <>
      {[...Array(rows)].map((row, index) => (
        <TableRow sx={{ border: 'transparent' }} key={index}>
          {[...Array(colCount)].map(() => (
            <TableCell sx={{ border: 'transparent' }}>
              <Skeleton animation="wave" variant="text" />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

interface EmptyStateRowProps {
  height: string;
  colCount: number;
  title: string;
}

export const TableRowEmptyState = ({
  height,
  colCount,
  title,
}: EmptyStateRowProps) => {
  return (
    <TableRow>
      <TableCell
        sx={{
          border: 'none',
          height,
          fontSize: FontSizes.fontSize17,
          opacity: '0.2',
          color: Colors.blackPearl,
        }}
        align="center"
        colSpan={colCount}
      >
        {title}
      </TableCell>
    </TableRow>
  );
};

interface TableCellWithLinkProps {
  handleClick: () => void;
  isActive: boolean;
  itemTitle: string | number | ReactNode;
  align?: 'left' | 'center' | 'right';
  children?: ReactNode;
  dataTestId: string;
  sx?: SxProps<Theme>;
}

export const TableCellWithLink = ({
  handleClick,
  isActive,
  itemTitle,
  align,
  children,
  dataTestId,
  sx = {},
}: TableCellWithLinkProps) => {
  return (
    <Typography
      onClick={handleClick}
      sx={{
        fontSize: FontSizes.fontSize14,
        fontFamily: fontSecondary,
        color: Colors.binge,
        textDecoration: 'none',
        cursor: isActive ? 'pointer' : 'default',
        textAlign: align || 'left',
        ...sx,
      }}
      data-testid={dataTestId}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {itemTitle}
        {itemTitle === 'External' && (
          <ExternalIcon style={{ marginLeft: '10px', color: Colors.binge }} />
        )}
        {itemTitle === 'Internal' && (
          <InternalIcon style={{ marginLeft: '10px', color: Colors.binge }} />
        )}
      </Box>
      {children}
    </Typography>
  );
};
