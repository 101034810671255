import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

//The table filter name should contain page name + table name
export enum FiltersTableName {
  ASSETS_REPOS_LIST = 'assets_repos_list',
  ASSETS_ARTIFACTS_LIST = 'assets_artifacts_list',
  ASSETS_CLOUD_LIST = 'assets_cloud_list',
  ALERTS_LIST = 'alerts_list',
  BRANCHES_LIST = 'branches_list',
  TEAMS_LIST = 'teams_list',
  ORG_TEAMS_LIST = 'org_teams_list',
  RECOMMENDATIONS_LIST = 'recommendations_list',
  IDENTITIES_ACCESS = 'identities_access',
  IDENTITIES_PIPELINE = 'identities_pipeline',
  IDENTITIES_OSS = 'identities_oss',
  IDENTITIES_RECOMMENDATIONS = 'identities_recommendations',
  ASSETS_DETAILS_CONTRIBUTORS = 'assets_details_contributors',
  ASSETS_NESTED_BRANCHES = 'assets_nested_branches',
  OPENSOURCE_REPOSITORIES_LIST = 'opensource-repositories_list',
  DETECTED_SECRETS_LIST = 'detected_secrets_list',
  ENTITY_GRAPH = 'entity-graph',
  POLICY_BUILDER = 'policy-builder',
  POLICIES_LIBRARY = 'policies-library',
  POLICIES_CUSTOM_POLICIES = 'policies-custom-polices',
  JIT_PENDING_REQUESTS = 'jit_pending',
  IAC_FINDINGS_TABLE = 'iac_findings',
  SAST_FINDINGS_TABLE = 'sast_findings',
  DAST_FINDINGS_TABLE = 'dast_findings',
  TRIVY_PR_SCANS_TABLE = 'trivy-pr-scans',
  PR_SECRET_SCANS_TABLE = 'pr-secrets-scans',
  ASSET_DETAILS_FOLDERS = 'asset-details-folders',
  ASSET_DETAILS_AUDITLOG = 'asset-details-audit-log',
  ASSET_DETAILS_ARTIFACTS = 'asset-details-artifacts',
  ASSET_DETAILS_SECRETS = 'asset-details-secrets',
  IDENTITIES_ALERTS_LIST = 'identities-alerts',
  SELF_ACCESS_TABLE = 'self-access-table',
  CLOUD_ALERTS_TABLE = 'cloud-alerts-table',
  CONTAINER_ALERTS_TABLE = 'container-alerts-table',
  IDENTITIES_CLOUD_ACCESS = 'identities_cloud_access',
  CLOUD_ACCESS_LIST = 'cloud_access_list',
}

export type Filter = Record<
  string,
  string | boolean | string[] | undefined | number | any[]
>;

export type FiltersState = Record<FiltersTableName, Filter>;

const initialState: FiltersState = {
  [FiltersTableName.ASSETS_REPOS_LIST]: {},
  [FiltersTableName.ASSETS_ARTIFACTS_LIST]: {},
  [FiltersTableName.ASSETS_CLOUD_LIST]: {},
  [FiltersTableName.ALERTS_LIST]: {},
  [FiltersTableName.IDENTITIES_ACCESS]: {},
  [FiltersTableName.IDENTITIES_PIPELINE]: {},
  [FiltersTableName.IDENTITIES_OSS]: {},
  [FiltersTableName.IDENTITIES_RECOMMENDATIONS]: {},
  [FiltersTableName.ASSETS_DETAILS_CONTRIBUTORS]: {},
  [FiltersTableName.ASSETS_NESTED_BRANCHES]: {},
  [FiltersTableName.RECOMMENDATIONS_LIST]: {},
  [FiltersTableName.BRANCHES_LIST]: {
    only_important: true,
  },
  [FiltersTableName.TEAMS_LIST]: {},
  [FiltersTableName.ORG_TEAMS_LIST]: {},
  [FiltersTableName.OPENSOURCE_REPOSITORIES_LIST]: {},
  [FiltersTableName.DETECTED_SECRETS_LIST]: {},
  [FiltersTableName.ENTITY_GRAPH]: {},
  [FiltersTableName.POLICY_BUILDER]: {},
  [FiltersTableName.POLICIES_LIBRARY]: {},
  [FiltersTableName.POLICIES_CUSTOM_POLICIES]: {},
  [FiltersTableName.JIT_PENDING_REQUESTS]: {},
  [FiltersTableName.IAC_FINDINGS_TABLE]: {},
  [FiltersTableName.SAST_FINDINGS_TABLE]: {},
  [FiltersTableName.DAST_FINDINGS_TABLE]: {},
  [FiltersTableName.TRIVY_PR_SCANS_TABLE]: {},
  [FiltersTableName.PR_SECRET_SCANS_TABLE]: {},
  [FiltersTableName.ASSET_DETAILS_FOLDERS]: {},
  [FiltersTableName.ASSET_DETAILS_AUDITLOG]: {},
  [FiltersTableName.ASSET_DETAILS_ARTIFACTS]: {},
  [FiltersTableName.ASSET_DETAILS_SECRETS]: {},
  [FiltersTableName.IDENTITIES_ALERTS_LIST]: {},
  [FiltersTableName.SELF_ACCESS_TABLE]: {},
  [FiltersTableName.CLOUD_ALERTS_TABLE]: {},
  [FiltersTableName.CONTAINER_ALERTS_TABLE]: {},
  [FiltersTableName.IDENTITIES_CLOUD_ACCESS]: {},
  [FiltersTableName.CLOUD_ACCESS_LIST]: {},
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFiltersForTable: (
      state: FiltersState,
      action: PayloadAction<{
        tableName: FiltersTableName;
        filters: Filter;
      }>,
    ) => {
      state[action.payload.tableName] = action.payload.filters;
    },
  },
});

export const { setFiltersForTable } = filtersSlice.actions;

export const filtersReducer = filtersSlice.reducer;
