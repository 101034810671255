import { InfoDetailsProps } from 'components/info-component/info-component';
import { TableColumnProps } from 'components/table/table-component/table-component';

export const tableStructureWithOrg: TableColumnProps[] = [
  {
    id: 'asset_name',
    title: 'Asset Name',
    withSort: true,
    position: 'left',
    withSearch: true,
    noFilters: true,
  },
  {
    id: 'service',
    title: 'Service',
    withSort: true,
    position: 'left',
    withSearch: true,
    noFilters: true,
  },
  {
    id: 'asset_type',
    title: 'Type',
    withSort: true,
    position: 'left',
    withSearch: true,
    noFilters: true,
  },
  {
    id: 'account_id',
    title: 'Account Id',
    withSort: true,
    position: 'left',
    withSearch: true,
    noFilters: true,
  },
  {
    id: 'account_name',
    title: 'Account Name',
    withSearch: true,
    withSort: true,
    noFilters: true,
    position: 'left',
  },
  {
    id: 'overall_passed',
    title: 'Passed',
    withSort: true,
    position: 'left',
  },
];

export const assetInfoStructure: InfoDetailsProps[] = [
  {
    id: 'name',
    title: 'Asset',
  },
  {
    id: 'highest_severity',
    title: 'Risk Rating',
  },
  {
    id: 'vulnerabilities',
    title: 'No. of Vulnerabilities',
  },
  {
    id: 'ossf_score',
    title: 'OSSF Score',
  },
  {
    id: 'source',
    title: 'Source',
  },
  {
    id: 'org',
    title: 'Organization',
  },
  {
    id: 'last_activity',
    title: 'Last Event',
    isDate: true,
  },
  {
    id: 'first_commit',
    title: 'First Event',
    isDate: true,
  },
  {
    id: 'entity_graph',
    title: 'Graph',
  },
];

export const assetInfoStructureJFrog: InfoDetailsProps[] = [
  {
    id: 'name',
    title: 'Asset',
  },
  {
    id: 'source',
    title: 'Source',
  },
  {
    id: 'org',
    title: 'Organization',
  },
  {
    id: 'last_activity',
    title: 'Last Event',
    isDate: true,
  },
  {
    id: 'first_commit',
    title: 'First Event',
    isDate: true,
  },
];
