import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import {
  closeModalByNameAction,
  PlainModals,
} from 'store/modules/modals/modals.reducer';

import {
  StyledTableBodyCell,
  StyledTableHeaderCell,
} from 'components/table/styles';
import {
  useGetModalMetaData,
  useIsPlainModalOpenSelector,
} from 'store/modules/modals/modals.selector';
import { ModalContainer } from 'components/modals/modal-container/modal-container';
import { paths } from 'constants/routes';
import { Member } from 'store/apis/types';
import { FontSizes } from 'constants/font-sizes';
import { fontSecondary } from 'constants/font-family';
import { Colors } from 'constants/colors';
import { ReactComponent as ExternalIcon } from 'assets/icons/external.svg';
import { setIdentitiesDetailsTab } from 'store/modules/identities/identities.reducer';

export const MembersModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const metaData = useGetModalMetaData();

  //@ts-ignore
  const members: Member[] = metaData?.members;

  const isOpenModal = useIsPlainModalOpenSelector(
    PlainModals.TEAMS_MEMBERS_MODAL,
  );

  const handleCloseModal = () => {
    dispatch(closeModalByNameAction(PlainModals.TEAMS_MEMBERS_MODAL));
  };

  return (
    <ModalContainer
      isOpenModal={isOpenModal}
      title="Team Members"
      handleCloseModal={handleCloseModal}
      modalWidth="700px"
      modalHeight="400px"
    >
      <TableContainer
        sx={{
          marginTop: '15px',
          maxHeight: '750px',
          border: `1px solid ${Colors.tableBorder}`,
        }}
      >
        <Table
          stickyHeader
          sx={{ minWidth: '550px', backgroundColor: 'transparent' }}
          size="small"
        >
          <TableHead sx={{ height: '42px' }}>
            <TableRow>
              <StyledTableHeaderCell align="left">
                Team Name
              </StyledTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members?.map((item, index) => (
              <TableRow
                key={index}
                sx={{
                  '&.MuiTableRow-hover:hover': {
                    backgroundColor: Colors.tableBorder,
                  },
                  '&:nth-of-type(odd)': {
                    backgroundColor: Colors.white,
                  },
                  '&:nth-of-type(even)': {
                    backgroundColor: Colors.tableCell,
                  },
                }}
              >
                <StyledTableBodyCell
                  sx={{
                    borderBottom: '0px',
                    borderTop: `1px solid ${Colors.cellBorder}`,
                  }}
                >
                  <Typography
                    onClick={() => {
                      dispatch(setIdentitiesDetailsTab(0));
                      navigate(
                        `${paths.identityDetails}?user=${item.login}&source=${metaData.source}`,
                      );
                      handleCloseModal();
                    }}
                    sx={{
                      fontSize: FontSizes.fontSize14,
                      fontFamily: fontSecondary,
                      color: Colors.binge,
                      textDecoration: 'none',
                      cursor: 'pointer',
                      textAlign: 'left',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {item.name || item.login}
                      <ExternalIcon style={{ marginLeft: '10px' }} />
                    </Box>
                  </Typography>
                </StyledTableBodyCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ModalContainer>
  );
};
