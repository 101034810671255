import { InfoDetailsProps } from 'components/info-component/info-component';
import { TableColumnProps } from 'components/table/table-component/table-component';

export const orgTeamsTableStructure: TableColumnProps[] = [
  {
    id: 'display_name',
    title: 'Department',
    withSort: true,
    withSearch: true,
    position: 'left',
    noFilters: true,
  },
  {
    id: 'manager',
    title: 'Manager',
    withSort: true,
    withSearch: true,
    position: 'left',
    noFilters: true,
  },
  {
    id: 'members_count',
    title: '# Members',
    withSort: true,
    position: 'left',
    noFilters: true,
  },
  // {
  //   id: 'repos_count',
  //   title: '# Repositories',
  //   withSort: true,
  //   position: 'left',
  //   noFilters: true,
  // },
  {
    id: 'total_vulnerability_counts',
    title: '# Vulnerabilites',
    withSort: true,
    position: 'left',
    noFilters: true,
  },
  // {
  //   id: 'options',
  //   title: '',
  //   position: 'left',
  // },
];

export const teamInfoStructure: InfoDetailsProps[] = [
  {
    id: 'name',
    title: 'Team',
  },
  {
    id: 'team_risk_score',
    title: 'Risk Score',
  },
  {
    id: 'average_vuln_per_repo',
    title: 'Avg Vuln. Per Repo',
  },
  {
    id: 'org',
    title: 'Organization',
  },
  {
    id: 'status',
    title: 'Status',
  },
  {
    id: 'total_repos',
    title: '# Repositories with access',
  },
  {
    id: 'identities',
    title: '# Identities',
  },
  {
    id: 'entity_graph',
    title: 'Graph',
  },
];
