import { TableColumnProps } from 'components/table/table-component/table-component';

export const labeledTeamReportTableStructure: TableColumnProps[] = [
  {
    id: 'display_name',
    title: 'Team Name',
    position: 'left',
  },
  {
    id: 'email',
    title: '# Email',
  },
  {
    id: 'oss_count',
    title: '# SCA',
  },
  {
    id: 'secret_count',
    title: '# Secrets',
  },
  {
    id: 'sast_count',
    title: '# SAST',
  },
  {
    id: 'dast_count',
    title: '# DAST',
  },
  {
    id: 'iac_count',
    title: '# IAC',
  },
  {
    id: 'cspm_count',
    title: '# CSPM',
  },
  {
    id: 'container_count',
    title: '# Container',
  },
  // {
  //   id: 'num_total',
  //   title: 'Members',
  // },
  // {
  //   id: 'num_inactive',
  //   title: 'Inactive members',
  // },
  // {
  //   id: 'repos_count',
  //   title: 'Repositories',
  // },
];
