/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useRef } from 'react';
import { PageHeader } from '../../components/page-components/page-header/page-header';
import {
  IAC_MAIN_TABLE,
  IAC_PAGE_HEADER,
  IAC_SCAN_CSV_DOWNLOAD,
  IDENTITIES_TABLE_NAME_LINK,
  POLICY_LIBRARY_SEVERITY_FILTER,
} from '../../constants/test-ids';
import {
  ExtraCellsObject,
  TableComponent,
} from '../../components/table/table-component/table-component';
import { tableStructure } from './constants';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { ApiContext } from '../../components/providers/api-provider/api-provider';
import { useGetResponseDataWithPagination } from '../../hooks/use-get-response-data-with-pagination';
import { Colors } from '../../constants/colors';
import { FontSizes } from 'constants/font-sizes';
import { TableCellWithLink } from 'components/table/table-components/table-components';
import { paths } from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import {
  RightTableFiltersContainer,
  TableFiltersContainer,
} from 'components/table/styles';
import { SeverityFilter } from 'pages/alerts/components/severity-filter/severity-filter';
import { useDispatch } from 'react-redux';
import { FiltersTableName } from 'store/modules/filters/filters.reducer';
import { RenderSourceCell } from 'components/info-component/info-component';
import { StyledButton } from 'components/button/button';
import { FilterChips } from 'components/table/table-filters-components/filter-chips/chips-list';
import {
  setApplicableFilters,
  setColumnCoordinates,
  setSelectedColumn,
  setSelectedTable,
} from 'store/modules/selected-column/selected-column.reducer';
import { useSelectedColumnSelector } from 'store/modules/selected-column/selected-column.selector';
import { CsvDownload } from 'components/table/table-filters-components/csv-download/csv-download';
import { setIdentitiesDetailsTab } from 'store/modules/identities/identities.reducer';

const mapSeverityToText: any = {
  '2': 'low',
  '3': 'medium',
  '4': 'high',
  '5': 'critical',
};

export const IacScan = () => {
  const apiContext = useContext(ApiContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tableId = FiltersTableName.IAC_FINDINGS_TABLE;
  const {
    applicableFilters: tableFilters,
    activeSortedField,
    activeSortedOrder,
    searchBy,
  } = useSelectedColumnSelector(tableId);

  const useLazyGetIacScanDataQuery =
    apiContext.baseApi?.useLazyGetIacScanDataQuery;

  const [open, setOpen] = React.useState(false);

  const [selectedSeverity, setSelectedSeverity] = React.useState(
    tableFilters?.severity?.toString() || 'all',
  );

  const handleSeverityDropdownChange = (event: SelectChangeEvent) => {
    const severity = event.target.value;
    setSelectedSeverity(severity);
    dispatch(
      setApplicableFilters({
        table: tableId,
        applicableFilters: severity !== 'all' ? { severity: severity } : {},
      }),
    );
  };

  useEffect(() => {
    dispatch(setSelectedTable({ table: FiltersTableName.IAC_FINDINGS_TABLE }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dialogContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (open && dialogContentRef.current) {
      dialogContentRef.current.focus();
    }
  }, [open]);

  const [metadataItem, setMetadataItem] = React.useState(null);

  const [stateData, setStateData] = React.useState<any>(null);

  const handleOpen = (item: any) => {
    setStateData(item);
    setOpen(true);
  };

  const handleClose = () => {
    setStateData(null);
    setOpen(false);
  };

  const {
    dataList: iacScanList,
    isLoading: isIacDataLoading,
    isError: isIacDataErrored,
    isFetching: isIacDataFetching,
    isNoFindings: isEmptyIacData,
    total,
  } = useGetResponseDataWithPagination({
    baseApiCallback: useLazyGetIacScanDataQuery,
    itemsPerPage: 10,
    tableFilters,
    severity:
      selectedSeverity === 'all'
        ? undefined
        : mapSeverityToText[selectedSeverity],
    sortBy: `${activeSortedOrder}${activeSortedField}`,
    tableId,
    searchBy,
  });

  const dialogContent = () => {
    return (
      <>
        {stateData?.provider && (
          <Box
            sx={{
              display: 'flex',
              mb: 1,
            }}
          >
            <Typography
              color={Colors.darkGray}
              fontSize={FontSizes.fontSize16}
              fontWeight={500}
              sx={{ minWidth: '150px' }}
            >
              Provider:{' '}
            </Typography>
            <Typography
              color={Colors.blackPearl}
              fontSize={FontSizes.fontSize16}
              fontWeight={600}
            >
              {stateData?.provider}
            </Typography>
          </Box>
        )}
        {stateData?.service && (
          <Box
            sx={{
              display: 'flex',
              mb: 1,
            }}
          >
            <Typography
              color={Colors.darkGray}
              fontSize={FontSizes.fontSize16}
              fontWeight={500}
              sx={{ minWidth: '150px' }}
            >
              Service:{' '}
            </Typography>
            <Typography
              color={Colors.blackPearl}
              fontSize={FontSizes.fontSize16}
              fontWeight={600}
            >
              {stateData?.service}
            </Typography>
          </Box>
        )}
        {stateData?.message && (
          <Box
            sx={{
              display: 'flex',
              mb: 1,
            }}
          >
            <Typography
              color={Colors.darkGray}
              fontSize={FontSizes.fontSize16}
              fontWeight={500}
              sx={{ minWidth: '150px' }}
            >
              Message:{' '}
            </Typography>
            <Typography
              color={Colors.blackPearl}
              fontSize={FontSizes.fontSize16}
              fontWeight={600}
            >
              {stateData?.message}
            </Typography>
          </Box>
        )}
        {stateData?.resolution && (
          <Box
            sx={{
              display: 'flex',
              mb: 1,
            }}
          >
            <Typography
              color={Colors.darkGray}
              fontSize={FontSizes.fontSize16}
              fontWeight={500}
              sx={{ minWidth: '150px' }}
            >
              Resolution:{' '}
            </Typography>
            <Typography
              color={Colors.blackPearl}
              fontSize={FontSizes.fontSize16}
              fontWeight={600}
            >
              {stateData?.resolution}
            </Typography>
          </Box>
        )}
        {stateData?.start_line && (
          <Box
            sx={{
              display: 'flex',
              mb: 1,
            }}
          >
            <Typography
              color={Colors.darkGray}
              fontSize={FontSizes.fontSize16}
              fontWeight={500}
              sx={{ minWidth: '150px' }}
            >
              Start Line:{' '}
            </Typography>
            <Typography
              color={Colors.blackPearl}
              fontSize={FontSizes.fontSize16}
              fontWeight={600}
            >
              {stateData?.start_line}
            </Typography>
          </Box>
        )}
        {stateData?.end_line && (
          <Box
            sx={{
              display: 'flex',
              mb: 1,
            }}
          >
            <Typography
              color={Colors.darkGray}
              fontSize={FontSizes.fontSize16}
              fontWeight={500}
              sx={{ minWidth: '150px' }}
            >
              End Line:{' '}
            </Typography>
            <Typography
              color={Colors.blackPearl}
              fontSize={FontSizes.fontSize16}
              fontWeight={600}
            >
              {stateData?.end_line}
            </Typography>
          </Box>
        )}
        {stateData?.lines && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              color={Colors.darkGray}
              fontSize={FontSizes.fontSize16}
              fontWeight={500}
              sx={{ minWidth: '150px' }}
            >
              Content:{' '}
            </Typography>
            <Box
              component="pre"
              sx={{
                backgroundColor: Colors.codeBackground,
                color: Colors.codeColor,
                padding: '16px',
                borderRadius: '4px',
                overflowX: 'auto',
              }}
            >
              {stateData?.lines.map((line: any) => (
                <Box>
                  <Typography
                    component="code"
                    sx={{
                      fontFamily:
                        'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace',
                      color: Colors.codeColor,
                    }}
                  >
                    {line.content}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </>
    );
  };

  const RenderStateCell = (item: any) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          sx={{ color: Colors.binge, cursor: 'pointer' }}
          onClick={() => handleOpen(item)}
        >
          View Data
        </Typography>
      </Box>
    );
  };

  const RenderAuthorLoginCell = (item: any) => {
    return (
      <>
        {item?.author_login ? (
          <TableCellWithLink
            handleClick={() => {
              handleClose();
              dispatch(setIdentitiesDetailsTab(0));
              navigate(
                `${paths.identityDetails}?user=${item?.author_login}${
                  item.source ? '&source=' + item.source : ''
                }`,
              );
            }}
            isActive={true}
            itemTitle={item.author_login}
            align="left"
            dataTestId={IDENTITIES_TABLE_NAME_LINK}
          />
        ) : (
          '-'
        )}
      </>
    );
  };

  const renderSourceCell = (item: any) => {
    return item?.source && <RenderSourceCell source={item?.source} />;
  };

  const formatSearchBy = (searchBy: any[]) => {
    const formattedSearchBy: any[] = [];
    searchBy.forEach((searchByObj) => {
      if (searchByObj.id === 'owner') {
        formattedSearchBy.push({ ...searchByObj, id: 'org' });
      } else if (searchByObj.id === 'author_name') {
        formattedSearchBy.push({ ...searchByObj, id: 'user' });
      } else if (searchByObj.id === 'repository') {
        formattedSearchBy.push({ ...searchByObj, id: 'repo' });
      } else if (searchByObj.id === 'file_path') {
        formattedSearchBy.push({ ...searchByObj, id: 'file' });
      } else {
        formattedSearchBy.push({ ...searchByObj });
      }
    });

    return formattedSearchBy;
  };

  const IACExtraCell: ExtraCellsObject = {
    causeMetadata: RenderStateCell,
    author_login: RenderAuthorLoginCell,
    source: renderSourceCell,
  };

  return (
    <Box>
      <PageHeader title="IaC Scan" dataTestId={IAC_PAGE_HEADER} />
      <TableFiltersContainer>
        {' '}
        <RightTableFiltersContainer>
          <FilterChips tableId={tableId} />
          <SeverityFilter
            value={selectedSeverity}
            onChange={handleSeverityDropdownChange}
            dataTestId={POLICY_LIBRARY_SEVERITY_FILTER}
            // showInformational={true}
            useMedium={true}
          />
          <CsvDownload
            total={total}
            activeSortedField={activeSortedField}
            activeSortedOrder={activeSortedOrder}
            apiUrl="findings/iac/detections"
            fileName="IAC Scan Finding"
            tableFilters={tableFilters}
            dataTestId={IAC_SCAN_CSV_DOWNLOAD}
            searchBy={
              searchBy && searchBy.length > 0 ? formatSearchBy(searchBy) : null
            }
          />
        </RightTableFiltersContainer>
      </TableFiltersContainer>

      <TableComponent
        tableId={tableId}
        isListLoading={isIacDataLoading}
        isListErrored={isIacDataErrored}
        isEmptyList={isEmptyIacData}
        isListFetching={isIacDataFetching}
        dataTestId={IAC_MAIN_TABLE}
        list={iacScanList}
        tableColumns={tableStructure}
        extraCellsRenderObj={IACExtraCell}
        columnFilterClickHandler={(column: any, coordinates?: any) => {
          dispatch(setSelectedColumn({ table: tableId, column: column?.id }));
          dispatch(setColumnCoordinates({ table: tableId, coordinates }));
        }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-paper': {
            background: Colors.white,
          },
        }}
      >
        <DialogContent>
          <Box sx={{ p: 2 }}>{dialogContent()}</Box>
        </DialogContent>
        <DialogActions>
          <StyledButton
            onClick={handleClose}
            color="secondary"
            variant="outlined"
            size="large"
          >
            Close
          </StyledButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
