import { InfoDetailsProps } from 'components/info-component/info-component';
import { TableColumnProps } from 'components/table/table-component/table-component';

export const tableStructureWithoutOrg: TableColumnProps[] = [
  {
    id: 'name',
    title: 'Asset Name',
    withSort: true,
    position: 'left',
    withSearch: true,
  },

  {
    id: 'source',
    title: 'Source',
    withSort: true,
    position: 'left',
  },
  {
    id: 'org',
    title: 'Organization',
    withSort: true,
    position: 'left',
    withSearch: true,
    noFilters: true,
  },
  {
    id: 'count_folders',
    title: 'No. of Folders',
    withSort: true,
    position: 'left',
    noFilters: true,
  },
  {
    id: 'count_artifacts',
    title: 'No. of Artifacts',
    withSort: true,
    position: 'left',
    noFilters: true,
  },

  {
    id: 'last_activity',
    title: 'Last Event',
    withSort: true,
    position: 'left',
    isDate: true,
    noFilters: true,
  },
];

export const tableStructureWithOrg: TableColumnProps[] = [
  {
    id: 'name',
    title: 'Asset Name',
    withSort: true,
    position: 'left',
    withSearch: true,
  },

  {
    id: 'source',
    title: 'Source',
    withSort: true,
    position: 'left',
  },
  {
    id: 'org',
    title: 'Organization',
    withSort: true,
    position: 'left',
    noFilters: true,
    withSearch: true,
  },
  {
    id: 'count_folders',
    title: 'No. of Folders',
    withSort: true,
    noFilters: true,
    position: 'left',
  },
  {
    id: 'count_artifacts',
    title: 'No. of Artifacts',
    withSort: true,
    noFilters: true,
    position: 'left',
  },

  {
    id: 'last_activity',
    title: 'Last Event',
    withSort: true,
    noFilters: true,
    position: 'left',
    isDate: true,
  },
];

export const assetInfoStructure: InfoDetailsProps[] = [
  {
    id: 'name',
    title: 'Asset',
  },
  {
    id: 'highest_severity',
    title: 'Risk Rating',
  },
  {
    id: 'vulnerabilities',
    title: 'No. of Vulnerabilities',
  },
  {
    id: 'ossf_score',
    title: 'OSSF Score',
  },
  {
    id: 'source',
    title: 'Source',
  },
  {
    id: 'org',
    title: 'Organization',
  },
  {
    id: 'last_activity',
    title: 'Last Event',
    isDate: true,
  },
  {
    id: 'first_commit',
    title: 'First Event',
    isDate: true,
  },
  {
    id: 'entity_graph',
    title: 'Graph',
  },
];

export const assetInfoStructureJFrog: InfoDetailsProps[] = [
  {
    id: 'name',
    title: 'Asset',
  },
  {
    id: 'source',
    title: 'Source',
  },
  {
    id: 'org',
    title: 'Organization',
  },
  {
    id: 'last_activity',
    title: 'Last Event',
    isDate: true,
  },
  {
    id: 'first_commit',
    title: 'First Event',
    isDate: true,
  },
];
