import { FilterItem } from 'components/multi-filter/multi-filter';
import { TableColumnProps } from 'components/table/table-component/table-component';

export const TeamSecretTableStructure: TableColumnProps[] = [
  {
    id: 'description',
    title: 'Secret Type',
    withSort: true,
    position: 'left',
  },
  {
    id: 'organization',
    title: 'Organization',
    withSort: true,
    noFilters: true,
    position: 'left',
  },
  {
    id: 'repository',
    title: 'Repository',
    withSort: true,
    noFilters: true,
    position: 'left',
  },
  {
    id: 'commit',
    title: 'SHA',
    position: 'left',
  },
  {
    id: 'user_login',
    title: 'User ID',
    withSort: true,
    noFilters: true,
    position: 'left',
  },
  {
    id: 'email',
    title: 'Email',
    withSort: true,
    position: 'left',
  },
  {
    id: 'created',
    title: 'Commit Date',
    withSort: true,
    noFilters: true,
    position: 'left',
    isDate: true,
  },
  {
    id: 'file',
    title: 'File',
    position: 'left',
  },
  {
    id: 'verified',
    title: 'Verified',
    withSort: true,
    position: 'left',
  },
];

export const TeamSastTableStructure: TableColumnProps[] = [
  {
    id: 'source',
    title: 'Source',
    withSort: true,
    noFilters: true,
    position: 'left',
  },
  {
    id: 'description',
    title: 'Description',
    withSort: true,
    position: 'left',
  },
  {
    id: 'owner',
    title: 'Organization',
    withSort: true,
    noFilters: true,
    position: 'left',
  },
  {
    id: 'repository',
    title: 'Repository',
    withSort: true,
    noFilters: true,
    position: 'left',
  },
  {
    id: 'author_email',
    title: 'User',
    withSort: true,
    noFilters: true,
    position: 'left',
  },
  {
    id: 'severity',
    title: 'Severity',
    withSort: true,
    noFilters: true,
    position: 'left',
  },
  {
    id: 'state',
    title: 'State',
    withSort: true,
    noFilters: true,
    position: 'left',
  },
  {
    id: 'file',
    title: 'File',
    position: 'left',
  },
  {
    id: 'updated_at',
    title: 'Updated',
    withSort: true,
    noFilters: true,
    position: 'left',
    isDate: true,
  },
];

export const TeamRegistryTableStructure: TableColumnProps[] = [
  {
    id: 'cve',
    title: 'CVE',
    withSort: true,
    noFilters: true,
    position: 'left',
  },
  {
    id: 'id',
    title: 'Registry',
    withSort: true,
    noFilters: true,
    position: 'left',
  },
  {
    id: 'package',
    title: 'Package',
    withSort: true,
    noFilters: true,
    position: 'left',
  },
  {
    id: 'discovered_date',
    title: 'Discovered',
    withSort: true,
    noFilters: true,
    isDate: true,
    position: 'left',
  },
  {
    id: 'severity',
    title: 'Severity',
    withSort: true,
    noFilters: true,
    position: 'left',
  },
  {
    id: 'user',
    title: 'User',
    withSort: true,
    noFilters: true,
    position: 'left',
  },
  {
    id: 'view_more',
    title: '',
    position: 'left',
  },
];

export const JFrogTeamRegistryTableStructure = () => {
  const columns = [...TeamRegistryTableStructure];
  columns[1] = {
    id: 'owner',
    title: 'Image',
    withSort: true,
    noFilters: true,
    position: 'left',
  };
  return columns;
};

export const TeamIacTableStructure: TableColumnProps[] = [
  {
    id: 'title',
    title: 'Title',
    withSort: true,
    noFilters: true,
    position: 'left',
  },
  {
    id: 'source',
    title: 'Source',
    withSort: true,
    noFilters: true,
    position: 'left',
  },
  {
    id: 'owner',
    title: 'Organization',
    withSort: true,
    noFilters: true,
    position: 'left',
  },
  {
    id: 'repository',
    title: 'Repo',
    withSort: true,
    noFilters: true,
    position: 'left',
  },
  {
    id: 'author_login',
    title: 'User',
    withSort: true,
    noFilters: true,
    position: 'left',
  },
  {
    id: 'severity',
    title: 'Severity',
    withSort: true,
    noFilters: true,
    position: 'left',
  },
  {
    id: 'file_path',
    title: 'File',
    withSort: true,
    noFilters: true,
    position: 'left',
  },
  {
    id: 'causeMetadata',
    title: 'Supporting Data',
    withSort: true,
    noFilters: true,
    position: 'left',
  },
];

export const TeamDastTableStructure: TableColumnProps[] = [
  {
    id: 'title',
    title: 'Title',
    position: 'left',
  },
  {
    id: 'url',
    title: 'URL',
    position: 'left',
  },
  // {
  //   id: 'is_present',
  //   title: 'Present',
  //   position: 'left',
  // },
  // {
  //   id: 'is_addressed',
  //   title: 'Addressed',
  //   withSort: true,
  //   position: 'left',
  // },
  // {
  //   id: 'author_login',
  //   title: 'User',
  //   withSort: true,
  //   position: 'left',
  // },
  {
    id: 'state',
    title: 'State',
    withSort: true,
    position: 'left',
  },
  {
    id: 'severity',
    title: 'Severity',
    withSort: true,
    position: 'left',
  },
  {
    id: 'first_seen_date',
    title: 'First Seen',
    isDate: true,
    position: 'left',
  },
  {
    id: 'last_seen_date',
    title: 'Last Seen',
    isDate: true,
    position: 'left',
  },
  {
    id: 'causeMetadata',
    title: 'Supporting Data',
    position: 'left',
  },
];

export const TeamOSSTableStructure: TableColumnProps[] = [
  {
    id: 'pkg',
    title: 'Package Name',
    position: 'left',
  },
  {
    id: 'last_release',
    title: 'Last Release',
    isDate: true,
    position: 'left',
  },
  {
    id: 'license',
    title: 'License',
    position: 'left',
  },
  {
    id: 'repo',
    title: 'Repository',
    position: 'left',
  },
  // {
  //   id: 'advisory_count',
  //   title: 'Security Advisory',
  //   position: 'left',
  // },
  {
    id: 'package_mgr',
    title: 'Package Manager',
    position: 'left',
  },
  {
    id: 'scaSeverity',
    title: 'Severity',
    position: 'left',
  },

  // {
  //   id: 'repositories',
  //   title: 'Repositories',
  //   position: 'left',
  // },
];

export const TeamCspmTableStructure: TableColumnProps[] = [
  {
    id: 'policy_name',
    title: 'Policy Name',
    position: 'left',
  },
  {
    id: 'severity',
    title: 'Severity',
    position: 'left',
  },
  {
    id: 'resource_name',
    title: 'Resource Name',
    position: 'left',
  },
  {
    id: 'account_id',
    title: 'Account Id',
    position: 'left',
  },
  {
    id: 'account_name',
    title: 'Account Name',
    position: 'left',
  },
  {
    id: 'cloudAccountOwners',
    title: 'Owner',
    position: 'left',
  },
];

export const TeamContainerTableStructure: TableColumnProps[] = [
  {
    id: 'name',
    title: 'Container Name',
    position: 'left',
  },
  {
    id: 'cluster',
    title: 'Cluster Name',
    position: 'left',
  },
  {
    id: 'scan_date',
    title: 'Scan Date',
    position: 'left',
    isDate: true,
  },
  {
    id: 'severity',
    title: 'Severity',
    position: 'left',
  },
  {
    id: 'account_id',
    title: 'Account Id',
    position: 'left',
  },
  {
    id: 'issues_count',
    title: '# Issues',
    position: 'left',
  },
  {
    id: 'owner',
    title: 'Owner',
    position: 'left',
  },
];

export const TeamDastFilterList: FilterItem[] = [
  {
    title: 'Severity',
    filterId: 'severity',
    filterColumns: ['severity'],
    props: [
      {
        label: 'Critical',
        value: 'Critical',
      },
      {
        label: 'High',
        value: 'High',
      },
      {
        label: 'Medium',
        value: 'Medium',
      },
      {
        label: 'Low',
        value: 'Low',
      },
      {
        label: 'Best Practice',
        value: 'BestPractice',
      },
    ],
  },
  {
    title: 'State',
    filterId: 'state',
    filterColumns: ['state'],
    props: [
      {
        label: 'Present',
        value: 'Present',
      },
      {
        label: 'FixedConfirmed',
        value: 'FixedConfirmed',
      },
      {
        label: 'Revived',
        value: 'Revived',
      },
      {
        label: 'FixedConfirmed, AcceptedRisk',
        value: 'FixedConfirmed, AcceptedRisk',
      },
      {
        label: 'Present, AcceptedRisk',
        value: 'Present, AcceptedRisk',
      },
    ],
  },
];

export const TeamSecretsFilterList: FilterItem[] = [
  {
    title: 'Status',
    filterId: 'status_filter',
    filterColumns: ['description'],
    props: [
      {
        label: 'Sent',
        value: 'sent',
      },
      {
        label: 'Open',
        value: 'open',
      },
    ],
  },
  {
    title: 'Verified',
    filterId: 'verified',
    filterColumns: ['verified'],
    props: [
      {
        label: 'Is verified',
        value: true,
      },
      {
        label: 'Is not verified',
        value: false,
      },
    ],
  },
];
